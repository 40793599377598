@import "../../GlobalVriables.scss";

div.container a.input-group-btn {
  font-size: $font-size;
}

.suggestions {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px #00000041;
  border: 1px solid #707070;
  border-radius: 100px;
  opacity: 1;
  align-items: center;
  padding-left: 0.6em; 
  width: 100%;
  // width: unset;
  // z-index: 1;
  // flex-direction: row;
  // margin: 0 auto;
  .MuiFormHelperText-root.Mui-error {
    padding: 1%;
    font-size: $font-size-20px;
  }
}

.input-group {
  flex-wrap: nowrap;
  flex-direction: column;
  // width: 41rem;
}

.search-button {
  background-color: $text-color-black;
  border-radius: 100%;
  height: 2.6em;
  width: 2.6em; 
  border: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
svg {
  overflow: hidden;
  vertical-align: middle;
  height: 2.2em;
}

.search-icon {
  width: 1.3em;
  // height: 1.56vw;
  // width: 1.56vw;
  // margin-bottom: 0.5em;
}

.input-group-btn {
  width: 7rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-left: 21px;
  // margin-left: 59vw;
}



.MuiTooltip-tooltip {
  background-color: $text-color-black !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid $text-color-black;
  font-size: $font-size-14px !important;
}

.MuiTooltip-arrow {
  padding-top: -1vw !important;
  color: $text-color-black !important;
}

.MuiTooltip-popper {
  margin-top: -1vw !important;
}

// .PrivateSwitchBase-root-1, .PrivateSwitchBase-root-12 {padding: 0 !important;}

.ExactLabel {
  span {
    padding: 0 !important;
  }
}
.tootltip-Font{
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}