@import "../../GlobalVriables.scss";

.results-info {
  margin: 1vw;
  color: #898989;
  font-size: 0.93rem;
}

.results {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-left: 0em;
  margin-right: 0em;
  flex-direction: row;
}

.results .result {
  // width: 96.7%;
}

.searchResult-txt{
  padding-top: 0rem;
}
@media screen and (max-width: 900px) and (min-width: 200px) {
  .searchResult-txt{
    padding-top: 2rem;
  }
}
