@import "../../GlobalVriables.scss";

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.bg-img{
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.header-bar{
  width: 100%;
  max-height: 10%;
  min-height: 10%;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.home-page {
  .petrofac-logo-wrapper {
    background-color: $background-color;
    // height: 3.64vw;
    padding-right: 1em;
  }
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: auto;
}

.home-search {
  margin: 18.28vw 18.9vw 30.72vw 18.95vw;
  max-width: 62.13vw;
  display: block;
}

.home-searchbar {
  // display: flex;
  .form-control {
    height: 100%;
    width: 86%;
    border-radius: 100%;
    // max-width: 49em;
    // min-width: 49em;
  }
  .MuiFormHelperText-root.Mui-error {
    color: white;
    font-size: $font-size-20px;
    font-family: $font-family;
    font-weight: 600;
  }
  .MuiInputBase-input {
    font-size: 0.8rem;
    // font-size: $font-size-1;
    padding: 0.5vw;
    width: 40rem;
    // border: 1px solid rebeccapurple;
  }
}

.regex {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  .regex-btn {
    color: white;
    border: 0.1vw solid;
    border-radius: 50%;
    text-transform: capitalize;
  }
}