@import "../../GlobalVriables.scss";

.header {
  background: $background-color;
  // box-shadow: 0px 2px 6px $box-shadow-color;
  color: $text-color-black;
  display: flex;
  width: 100%;
  justify-content: space-between;
  // min-height: 4em;
  // max-height: 4em;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1em;
  padding-right: 1em;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  width: 100%;
}


.navbar {
  padding: 0 !important;
  // flex-wrap: nowrap !important;
}

.navbar-nav {
  flex-direction: row !important;
  flex-wrap: wrap;
  .MuiFormControl-root {
    // margin-top: 2vw !important;
    // width: 1vw !important;
    .MuiInput-underline {
      border-bottom: 0;
      &::after {
        border-bottom: 0;
      }
      &:hover {
        border-bottom: 0 transparent;
      }
      &::before {
        border-bottom: 0;
      }
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    .MuiPopover-paper {
      margin-top: 3vw;
    }
  }
}

.collapse {
  min-height: 3.64vw;
  // margin-left: 63vw;
  justify-content: flex-end;
}

.navbar-logo {
  // width: 2.08vw;
  height: 1.7em;
  border-radius: 100%;

}

.petrofac-logo-header {
  height: 2rem;
  cursor: pointer;
  padding-right: 0.8rem;
}

.petrofac-logo-wrapper {
  // height: 3vw;
  // width: 23.2%;
  display: flex;
  align-items: center;
}

.nav-title {
  color: $text-color-black;
  padding-bottom: 0;
}

.nav-link {
  color: $text-color-black;
  // font-size: $font-size-3;
}

.nav-link:hover {
  color: $text-color-black;
}

.nav-logout {
  text-decoration: underline;
  cursor: pointer;
}

.logout-wrapper {
  border-left: 1px solid;
  display: flex;
  align-items: center;
  // width: 8.5vw;
}
@media screen and (max-width: 1000px) and (min-width: 200px) {
  .logout-wrapper {
    border-left: 1px solid;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 5px;
  }
}

.logout-icon {
  height: 1.2em;
  margin-left: 1em;
}

.auth-link {
  color: $text-color-black;
}

.auth-link:hover {
  color: $text-color-black;
}

.userName {
  font-size: 0.93em;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 0.5em;
  margin-bottom: 0rem;
}

.nav-user {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 1rem;
  margin-left: 1rem;
  //padding-left: 1em;
  //padding-right: 1em;
}
// @media screen and (max-width: 1000px) and (min-width: 200px) {
//   .nav-user {
//     display: flex;
//     align-items: center;
//     align-content: center;
//     justify-content: center;
//     flex-wrap: wrap;
//     // padding-left: 0em;
//     padding-right: 1em;
//   }
// }

.logout-text {
  font-size: 0.93rem;
  margin-bottom: 0;
  margin-left: 0.5em;
  display: flex;
}
.nav-link {
  display: flex !important; 
  padding: .5rem 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.help {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  align-items: center;
  color: #000;
  text-decoration: none;
  justify-content: center;

  // margin-right: 1vw;
  padding-right: 1em;
    &:hover {
      color: #000;
      text-decoration: none;
    }
    svg {
      width: 1.7rem !important;
      // height: 2vw !important;
      color: #000 !important;
      .MuiSvgIcon-root { 
        color: #000 !important;
      }
    }
}

.timeout-session-popup {
  .modal-body {
    display: grid !important;
    height: 2vw !important;
    text-align: center;
    align-content: space-evenly;
    background-color: #f7f8fa;
    width:35vw !important;
    // padding: 0.41vw;
    // border-radius: 5px;
    //     display: flex;
    //     justify-content: center;
  }
  .modal-content {
    border-top: 0.625em solid #0c629b !important;
    width:35vw !important;
    left: 31vw !important;
  }
  .modal-content label {
    font-weight: normal;
  }
}
.MuiSvgIcon-root {
  fill: currentColor;
  // width: 1em;
  // height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
}

// @media screen and ( min-width: 900px ) {
//   .headerbar-serachbar {
//     background-color: red ;
//   }
// }


@media screen and ( min-width: 1000px ) {
  .headerbar-searchbar{
    width: 48rem;
    padding-right: 4rem;}
    // @media screen and ( max-width: 900px )  {
    //   .headerbar-searchbar{{
    //     width: 35rem;
    //     padding-right: 50px;
    //   }
    // }
}


@media screen and (max-width: 1000px) and (min-width: 200px) {
  .headerbar-searchbar{
    width: 100%;
  }
  .navbar-nav {
    flex-wrap: nowrap !important;
    align-items: center !important;
  }
  .logout-icon {
    height: 1.2em;
    margin-left: 5px;
  }
  .nav-user {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: 6px;
    margin-left: 15px;
  } 
  .header {
    background: #FFFFFF;
    color: #000000;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;
  }

}

.tootltip-Font{
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.navbar-list-item{
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

