@import "../../../GlobalVriables.scss";

.result {
  text-align: center;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px #ccc;
  margin: 1vw;
  margin-bottom: 0.5vw;
  box-sizing: border-box;
  max-width: 100%;
}

.result-link {
  text-decoration: none;
}

.title-style {
  vertical-align: middle;
  color: $primary-color;
  // font-size: $font-size-20px;
  font-size: 1.04rem;
  text-align: left;
  margin-top: 1vw;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 93%;
  // height: 1vw;
  // text-transform: capitalize;
}

.doctype-icon {
  height: 1.5rem;
  margin: 0.6vw;
  padding-left: 0.1rem;
}
.result-title {
  width: 93%;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $primary-color;
  }
}
.cardTitleBg{
  width: 100%;
}
.docType-docTitle-bg{
  display: flex;
  flex-direction: row;
}
.result-download{
  display: flex;
  justify-content: flex-end;
}

.result-head {
  display: flex;
  background: #eff5f8;
  border-radius: 2px;
  opacity: 1;
  justify-content: space-between;
}

.preview-icon {
  margin-right: 2vw;
  margin-left: 2vw;
  cursor: pointer;
  width: 1.5vw;
  height: 1.5vw;
  margin-top: 0.7vw;
}

.download-icon {
  cursor: pointer;
  height: 1.3rem;
  margin-top: 0.7vw;
  padding-right: 0.3rem;
}

.result-body {
  border-bottom: 1px solid #e8e9eb;
  padding: 1%;
}

.date-language {
  text-align: left;
  font-size: 0.83rem;
  // font-size: $font-size-16px;
  color: #000;
  padding-bottom: 0.2vw;
  font-weight: bold;
}

.result-lines {
  color: $text-color-black;
  font-size: 0.93rem;
  // font-size: $font-size-18px;
  text-align: justify;
  overflow: hidden;
  color: $grey-1;
}

.tag-list {
  display: flex;
  overflow: hidden;
  width: 92.5%;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 0.8%;
}

.tag {
  background: #EFF0F2;
  border-radius: 100px;
  margin-left: 5px;
  height: 2.08rem;
  border: none;
  display: inline-flex;
  outline: none;
  margin: 0 0.4rem 0.52rem 0;
  font-size: 1vw;
  align-items: center;
  white-space: nowrap;
  border-radius: 1vw;
  justify-content: center;
}

.tag-name {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1vw;
  // font-size: $font-size-16px;
  font-size: 0.83rem;
}

.see-more-tags {
  color: $primary-color;
  text-decoration: underline;
  margin-top: 0.8%;
  cursor: pointer;
  font-size: $font-size-1;
  margin-left: 2.8vw;
}

.modal {
  padding-left: 0 !important;
}

.fullmodal {
  max-width: 100vw;
  // width: 98vw;
}

.YourHighlightClass {
  background-color: #fff72e;
  color: $text-color-black;
}
.tootltip-Font{
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
