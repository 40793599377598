@import "../../GlobalVriables.scss";


.facetbox{
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
}

.box {
  display: flex;
  height: 100%;
  box-shadow: 0px 3px 6px #00000041;
  background-color: #FFFFFF;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #e8eaeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
  }
}

.listitem {
  margin-top: 0 !important;
  padding-top: 0;
}

.filterlist {
  list-style: none;
}

.reset-icon {
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
}

.filters-title {
  font-size: 0.93rem;
  margin-left: 10px;
  // font-size: $font-size-18px;
  // width: 93%;
  // margin-left: 0.3vw;
}

.filters {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.facet-settings {
  height: 2rem;
  background: $primary-color;
  box-shadow: 0px 3px 6px #00000029;
  color: $background-color;
}

.chip {
  margin: 0.25em;
}

.MuiSvgIcon-root {
  color: $primary-color !important;
  width: 1.1rem !important;
  // width: 1.5vw !important;
  // height: 1.5vw !important;
}

.MuiListItem-dense {
  padding: 0;
}

.MuiCollapse-wrapperInner {
  background-color: $tag-background-color;
}

.MuiListItem-button {
  padding-left: 1.5vw !important;
}

.MuiList-root {
  margin-right: 0 !important;
  &::-webkit-scrollbar {
    width: 0.5vw;
    height: 0.5vw;
  }
  &::-webkit-scrollbar-track {
    background: #e8eaeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
  }
}
.tootltip-Font{
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}