@import "../../GlobalVriables.scss";


.header-bar{
  width: 100%;
  max-height: 10%;
  min-height: 10%;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.search-result-bg{
  width: 100%;
}

.sui-layout-header {
  background-color: #0078d7;
  color: $background-color;
}
.sui-search-box__submit {
  background: linear-gradient(rgb(60, 226, 102), rgb(34, 151, 57));
  letter-spacing: 0.1em;
}
.sui-search-box__submit:hover {
  background: linear-gradient(rgb(34, 151, 57), rgb(60, 226, 102));
}

.search-main {
  padding: 0;
}

.search-page {
  min-height: 100vh;
  // background-color: rgb(247, 248, 250);
  ._loading_overlay_overlay {
    margin-top: 0px;
  }
}

.pager-style {
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
}

.home-searchbar {
  .search-bar {
    z-index: 9;
    width: 51vw;
    .input-group-btn {
      z-index: 9;
      margin-left: 47vw;
    }
    .search-button {
      border: transparent;
      background-color: transparent;
      margin-top: 0;
      height: 0;
      padding: 0;
      // padding-bottom: 1.8vw;
      .search-icon {
        height: 1.3vw;
        margin-left: 1.7vw;
        margin-bottom: 1vw;
      }
      #Search_Icon {
        fill: $text-color-black;
      }
    }
    .suggestion-item {
      font-size: $font-size-17px;
    }
  }
}
.search-main {
  .search-bar {
    z-index: 9;
    width: 100vw;
    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .suggestions {
        width: unset;
        display: flex;
        flex-direction: row;
      }
      .form-control {
        //width: 52.1vw;
        //max-width: 1000px;
        // max-width: 57em;
        // min-width: 57em;
        // padding-left: 0.5vw;    
        // height: 2.5vw;
        // background: $background-color;
        // border-radius: 2vw;
        // border: transparent;
        // box-shadow:0 3px 6px rgba(0, 0, 0, 0.25);
        // margin-top: 0.5vw;
        // margin-left: 1vw;
        // font-size: $font-size-18px;
        .MuiInputBase-input {
          font-size: 0.8rem;
          padding: 0.5vw;
          width: 85%;
        }
    }
  }
    .input-group-btn {
      z-index: 9;
      // margin-left: 48vw;
    }
    .search-button {
      border: transparent;
      background-color: transparent;
      margin-top: 0;
      // height: 3vh;
      padding: 0;
      .search-icon {
        height: 1.3vw;
      }
      #Search_Icon {
        fill: $text-color-black;
      }
    }
    .suggestions-box {
      width: 52vw;
      max-width: 1000px;
    }
    .suggestion-item {
      font-size: $font-size-17px;
    }
  }
}


.form-control {
  border: transparent;
  border-bottom: 0.1vw solid;
  border-radius: 0;
  margin-top: 1vw;
  height: 100%;
  font-size: 0.9rem;
  // height: 1.38vw;
  // font-size: $font-size-18px;
}

.sortBy {
  .sort-dorpdown {
    // margin-left: 59vw;
    // margin-top: 1vw;
    // font-size: $font-size-18px;
    font-size: 0.93rem;
    margin-bottom: 0rem;
  }
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  width: 100%;
  padding-right: 0.5rem;
  margin-top: 0.7rem;
  .MuiSvgIcon-root {
    height: unset;
    top: unset;
  }
  .MuiFormControl-root {
    width: 6.3rem !important;
    margin-left: 0.5vw;
    /* height: 2.52vw; */
    /* background: #FFFFFF; */
    /* box-shadow: 0px 2px 10px #0000000f; */
    /* border: 0.05vw solid #CCCCCC; */
    /* border-radius: 0.2vw; */
    // margin-top: 0.5vw;
    .MuiInputBase-root {
      height: 1.2rem;
      padding-left: 0.5rem;
    }
    .MuiInput-underline {
      border-bottom: 0;
      &::after {
        border-bottom: 0;
      }
      &:hover {
        border-bottom: 0 transparent;
      }
      &::before {
        border-bottom: 0;
      }
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    .MuiSelect-selectMenu {
      // font-size: $font-size-18px;
      font-size: 0.93rem;
      padding: unset;
      display: flex;
      align-items: center;
    }

    .MuiList-padding {
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiPopover-paper {
      margin-top: 3vw;
    }
    .MuiButtonBase-root .MuiListItem-root .MuiMenuItem-root .Mui-selected .MuiMenuItem-gutters .MuiListItem-gutters .MuiListItem-button .Mui-selected {
      font-size: $font-size-18px !important;
      font-family: Helvetica;
    }
    .MuiMenu-list {
      .MuiMenuItem-root {
        font-size: $font-size-18px !important;
      }
    }
    li {
      font-size: $font-size-18px !important; 
    }
  }
}

.col-md-9 {
  height: 91.8vh;
  top: 4.5vw;
}

#pdf-page-wrapper .react-pdf__Page__textContent {
  display: none;
}

.PrivateSwitchBase-root-1 {
  padding: 0;
}
//Sougata added below css class
.sortBy-bg{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}